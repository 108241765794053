export const theme = {
  main_color: "#eae8e5",
  main_color_dark: "#edeae1",
  logo_color_light: "#539193",
  logo_color_dark: "#234F66",
  success_light: "#d5e6d6",
  warning_light: "#ffcfcf",
  warning_dark: "#ee5253",
  site_light: "#ffc4c4",
  font_family: "sans-serif-condensed",
  social_bg: "#e3ebff",
  mail_background: "#dcdff2",
  main: {
    main_color: "#234F66",
    success_color: "#77c080",
    secondary_color: "white",
    beta_color: "#e6c7a3",
  },
  residence: {
    main_color: "#e89d43",
    success_color: "#76cc7a",
    secondary_color: "#eae8e5",
    beta_color: "#e6c7a3",
    card_color: "#b7d2d6",
  },
  non_residence: {
    main_color: "#3a80e8",
    success_color: "#77c080",
    secondary_color: "#f0f7ff",
    beta_color: "#d7e9fc",
  },
  interior: {
    main_color: "#50b0d6",
    success_color: "#77c080",
    secondary_color: "#fff5f5",
    beta_color: "#ffe49f",
    card_color: "#d9cfc5",
  },
  url: {
    baseURL: "https://msrconstruction.co.in",
    emailApi: "https://api.emailjs.com/api/v1.0/email/send-form",
    facebook: "https://facebook.com",
    instagram: "https://www.instagram.com/msr.construction/",
    youtube:
      "https://www.youtube.com/channel/UCTzligOnyw0WNCJH01tZBiQ/featured",
    whatsapp: "https://wa.me/%2B917904426625",
    phone: "tel:7904426625",
    location: "https://goo.gl/maps/y86sNQiwxSnpQ2dK8",
  },
};
